


.root {
	height:100%;
}
.appRoot{
	height:calc(100vh);
	width:100%;
	display:flex;
	flex-direction:column;
 	align-items: stretch;
}
.fullscreen-dialog {
	z-index: 99999;
}
.appFrame{
 flex:1;
 display: flex; 
 heightx:calc(100% - 40px);
 borderx:5px solid blue;
 widthx:calc(100% - 10px);
}

.mainPage {
  /*background-color: blue;*/
  height:100%;
  background-colorx:rgb(224, 224, 224); 
  width:100%;
  borderx: 2px solid red;
  margin:0px;
  padding:5px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  position:relative;
}

.mainPageContent {
  
  height:100%;
  width:100%;
  margin:0px;
  padding:0px;
  display:flex;
  flex-direction:row;
  background-colorx: rgb(224, 224, 224)
}
.standard-head-wrapper {
	display:flex;
	justify-content:flex-start;
	align-items:center;
	margin-top:.5em;
	height:4em;
	borderx:1px solid red;

}
.mainPageColumn{
  background-colorx:#fff;
  background-color:#fff;
  padding:.2em .2em .2em .2em;
  margin:.2em;
  border: 1px solid grey;
  border-radius: 10px;
  align-items: stretch;
}
.mainPageColumnNoMargins{
  background-colorx:#fff;
  background-color:#fff;
  border: 1px solid grey;
  border-radius: 10px;
  align-items: stretch;
  height: 100%;
}
.mainPageHeader{
  background-colorx:#fff;
  paddingx:.2em .2em .2em .2em;
  margin-left:.2em;
  borderx: 1px solid grey;
  border-radiusx: 10px;
  align-items: stretch;
}

.mainPageLeftColumn{
	paddingx:.5em 0em 0em 0em;
	heightx:calc(100% - 20px );
	heightx:100%;
	widthx:30%;
	display:flex;
	flex-direction:column;
	margin:1em 1em 1em 1em;
}

.mainPageCenterColumn{
	paddingx:5px 0px 5px 5px;
	width:100%;
	heightx:calc(100% - 20px );
	display:flex;
	flex-direction:column;
  	borderx:1px solid red;
  	height:100%;
	background-color: rgb(224, 224, 224);
	border:none;
}
.mainPageTwoThirdsColumn{
	paddingx:5px 0px 5px 5px;
	widthx:100%;
	heightx:calc(100% - 20px );
	display:flex;
	flex-direction:column;
  	borderx:1px solid red;
  	height:100%;
}

.mainPageRightColumn{
	padding:5px 5px 5px 5px;
	width:30%;
	heightx:calc(100% - 20px );
	display:flex;
	flex-direction:column;
	background-colorx: red;
}

.mainPageFullColumn{
	width:100%;
	display:flex;
}


.expansionPanelContent{
  background-colorx:lightgray;
  margin-top:2em;
  height:100%;
}

.heading-text: {
    line-height:58px;
    font-size:2em;
    font-weight:600;
    color:#fff;
    marginRightx:30px;
    border: 1px solid red;
}

.headingTextBeta: {
	color:red;
	font-size:.5em;
}

.overflowEllipsis {
 text-overflow: ellipsis;
 white-space: nowrap;
 overflow: hidden;
 widthx:10em;
}

.listTitle {
  
}

.listSubTitle {
  
}

.loadButton {
  color:red
}

.primaryListItemWrapper{

	borderx: 1px solid #d0d0d0;

	

}
.primaryListItem{

	
	display:flex;
	justify-content:flex-start;
	paddingx:.2em;
	width:100%;
	height:4em;
}

.primaryListItem_left{

	display:flex;
	flex-direction:column;
	justify-content:center;
	padding-topx:.5em;
	padding-bottomx:.5em;
	
}

.primaryListItem_right{

	displayx:flex;
	justify-content:space-between;
	width:100%;
    align-items:center;
    margin-left:.5em;
    margin-right:.5em;
	padding-top:.5em;
	padding-bottom:.5em;


    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;

    


	
}

.listItemThumbnailWrapper2{

   height:100%;
   border:1px solid #009688;
   width:3em;
   overflow:hidden;
   margin-right: 1em;



}

.listItemThumbnail2{

   
   display:flex;
   justify-content:flex-start;
   align-items:center;
   borderx:1px solid red;
   widthx:3.0em;
   height:100%;
   padding-right:5%;

}

.listItemThumbnail2 img{

   
   displayx: block;
   height: 100%;
   max-widthx: 100px; /*actual image width*/
   heightx: auto; /* maintain aspect ratio*/
   margin: auto; /*optional centering of image*/

}


.listItemWrapper{

   


}

.listItemThumbnail{

   
   display:flex;
   justify-content:flex-start;
   align-items:center;
   borderx:1px solid red;
   width:3em;
   height:100%;

}

.listItemThumbnail img{

   
   display: block;
   heightx: 80%;
   height:100%;
   max-width: 100%; /*actual image width*/
   heightx: auto; /* maintain aspect ratio*/
   margin: auto; /*optional centering of image*/
   padding: .5em 0em .5em 0em;

}

.listItemTitle{
	margin-leftx:1em;
	margin-rightx:1em;
	font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    borderx:1px solid red;
}

.listItemSubTitle{
	margin-leftx:1em;
	margin-rightx:1em;
	font-size: .85rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weightx: 400;
    line-heightx: 1.75;
    letter-spacingx: 0.00938em;
    color:grey;
    borderx:1px solid red;
}



.primaryListItemSelected{

	border: 1px solid rgb(224, 224, 224);
}


.selectedListItem {

	border-bottom:1px solid #eeeeee;
	border-left: 7px solid #009688;
	height:100%;
	display:flex;
	justify-content:flex-start;
	alignItems:flex-start;	
	margin-right:2px;
		        					
}

.unSelectedListItem {

	border-leftx: 7px solid #fff;
	padding-left:7px;
	display:flex;
	justify-content:flex-start;
	alignItems:flex-start;
	margin-right:2px;	
		        					
}

.footerbutton {
	display:flex;
	justify-content:center;
	alignItems:center;
	margin-top:7px;
	margin-bottom:7px;
}

.formfieldtitle {
	colorx:rgb(84, 84, 84);
	color: #808080;
}
.form-field-section-title {
	margin-top:0em;
	margin-bottom:1em;
}
.scrollbar {

	height: 100%;
	overflow: auto;
}

#style-1::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
} 

#style-1::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.popupLogo {
	height:8em;

}

.popupHeader {
  font-size:18px;
  background-color: white;
  text-align: center;
  padding:5px 0px 5px 0px;
}

.popupWrapper {
  overflowx:hidden;
}

.popupContent2 {
  height:'100%';
}


.popupContent {
  /*background-color: #00ccb8;*/
  background-colorx: #009688; /* BOOKMERLIN green*/
  background-color: #ffffff;
}

.popupEmptyContentText {
  colorx: #009688; /* BOOKMERLIN green*/
  color:#A9A9A9;
  font-size:1.5em;
}

.popupEmptyContentTextWhite {
  color: white; 
  font-size:1.5em;
}

.empty-content-text {
	colorx: #009688; /* BOOKMERLIN green*/
	color:#A9A9A9;
	font-size:1.5em;
	text-align: center;
  }
  
  .empty-content-text-white {
	color: white; 
	font-size:1.5em;
	text-align: center;
  }


.helpLink {
  font-size:1.25em;
  color:#A9A9A9;
}
.helpLink-template {
	color:#707070;
}
.helpLink a:link {
  text-decoration: none;

}

.helpLink a:visited {
  text-decoration: none;
}

.helpLink a:hover {
  color: red;
}

.helpLink a:active {
  text-decoration: none;
}

.helpDrawer {
	widthx:30em;
}

.helpDrawerContent {
	height:100%;
	/*background-color: #e0ebeb;*/

}

.treeNodeIcons{
	colorx:#009688;
	color:#B2BEC3;
	colorx:#636E72;
	margin-right: .1em;
}

.tree-node-has-tags {
	color:red;

}
.tree-node-unsorted {
	color:orange;
}
.treeNode-check-icon{
	color:#009688;
	margin-right: .1em;
	height:.4em;
	widthx:.1em;
}

.treeNode-red-icon {
	color:red;
}

.treeNode-green-icon {
	color:#009688;
}

.treeNode-note-color-icon {
	color:#F5D76E;
}

.ant-tree li {
	padding:0.1em;
}

.ant-tree-child-tree > li:first-child {
	padding:0.1em;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background-color:#DFE6E9;


}

.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
	widthx:auto;
}

.carousel .slide {
    backgroundx: unset;
}
/* carousel */
.carousel img {
	width: auto;
}

.control-dots .dot {
	background-color: #009688;
	width: 16px;
    height: 16px;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot  {
	width: 16px;
    height: 16px;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot  {
	width: 16px;
    height: 16px;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover  {
	background-color: #009688;
	width: 16px;
    height: 16px;
}

.templateSelectorWrapper {
	height:10em;
	background-color: tan;
}

.templateSelector {
	padding: .2em .2em .2em .2em;
}


.formFieldLabel {
	color:#A7A39E;

}

.MuiFormControlLabel-label {
	color:#808080;
}

.MuiOutlinedInput-root {
	height:2.5em;
}

.MuiFormControl-rootx {
	height:2em;
}

.MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(1em,.8em) scale(1);
    pointer-events: none;
}

.autoupdate-field {
	display:flex; 
	align-items:center; 
	width:100%; 
	justify-content:flex-end;
	borderx:solid 1px red;
	margin-bottom:.5em;
}

.autoupdate-field-list > label {
	background-colorx:#ffffff;
	padding: 0em .2em 0em .2em;
}

.properties-subsection {
	width:100%;
}
.search-field {
	width:100%;
}
.property-fieldset {
	position:relative;
	width:100%;
	margin-bottom: 0em;
}

.property-legend-2 {
	borderx: 1px black solid;
	width:auto;
    height: 11px;
	margin: 0em 0em 0em 1em;
	padding: 0;
	color: #808080;
    font-size: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: inherit;
    letter-spacing: 0.00938em;
    transform: translate(1px, -1px) scale(0.75);
    text-align: left;
    transitionx: width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    line-heightx: 11px;


}
.property-legend-wrapper {
	display:flex;
	justify-content: center;
	align-items: center;
}
.property-legend{
	color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.property-field-legend {
	text-align: left;
	font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #808080;
    padding-left:9px;
}
.property-field-legend-radio {
	text-align: left;
	font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #808080;
}
.property-fieldset-checkbox {
	padding-left:8px;
	border:none;
}
.property-fieldset-checkbox-flex{
	display:flex;
	borderx:1px solid brown;
	align-items:center;
	padding: 0em 0em 0em 0em;
	margin: 0em 0em 0em 0em;
	justify-content:flex-start;
}
.property-fieldset-image {
	margin-leftx:8px;
	heightx:6em;
	paddingx:2em 0em 2em 0em;
}

.radio-buttons-horizontal{
	display:flex;
	flex-direction:row;
	width:100%;
	borderx:1px solid blue;
	justify-content:flex-start;
}
.radio-group-wrapper {
	display:flex; 
  	flex-direction:column;
	align-itemsx:center; 
	width:100%;
	justify-content:flex-end;

} 

.radio-group-wrapper-horizontal {
	display:flex; 
  	flex-direction:row;
	align-items:center; 
	width:100%;
	justify-content:flex-start;

} 

.radio-button-wrapper {
	display:flex; 
	align-items:center; 
	widthx:100%;
	justify-content:flex-start;
	margin-right:2em;

} 
.absolute-help-link {
	position:absolute;
	bottom:0.5em;
	right:0em;
	marginx: 0em 14px .3em 0em;
	z-indexx: 9999;
}
.detail-page {
	height:100%;
	width:100%;
	overflow-y:hidden;
	margin-bottom: 0em;
}
.detail-page-fields-wrapper {
	displayx:flex;
	flex-direction:column; 
	justify-contentx:center;
	align-itemsx:center; 
	width:100%;
	overflow-yx:auto; 
	heightx:calc( 100% - 58px);
	borderx:10px solid blue;
	border-radiusx:15px;
}
.detail-page-fields-group {
	display:flex;
	justify-content:space-between;
	width:100%;
	padding: 0em .5em .5em .5em;
	borderx:1px solid green;
	height:100%;
	margin-bottom:5em;
	
}

.detail-page-image-property {
	margin-bottom: 2em;
}

.detail-title-wrapper {
	display:flex;
	justify-content:flex-start;
	align-items: center;
	margin-bottom: 0em;
}
.detail-image-title-wrapper {
	display:flex;
	justify-content:flex-start;
	align-items: center;
	color: #009688;
	text-align: center;
}
.detail-green-title-label {
	color: #009688;
	overflow: hidden;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

}

.detail-title-label-2 {
		color: #009688;
		font-size: 1rem;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		font-weight: 400;
		line-height: 1.75;
		letter-spacing: 0.00938em;
	
}

.detail-title-label {
	colorx: #009688;
	color: rgba(255, 255, 255, 0.7);
	padding: 6px 12px;
    overflow: hidden;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

}


.detail-title-help-icon {

}

@media only screen and (max-width: 1024px) {
  
  .detail-page-fields-group-column {
	display:flex;
	flex-direction:column; 
	width:100%;

	}

	.detail-page-fields-group-column > * {
	    margin-bottomx: .5em;
	}

	.detail-page-major-labels {
		font-size: 0.875rem;
		margin-left:7px;
	    /*min-height: 48px;*/
	    text-align: center;
	    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	    font-weight: 500;
	    line-height: 1.75;
	    white-space: normal;
	    letter-spacing: 0.02857em;
	    text-transform: uppercase;
	    color: #009688
	}

	.detail-image-title-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #009688;
		text-align: center;
	}
	



}
@media only screen and (min-width: 1025px) {
  
  .detail-page-fields-group-column {
	display:flex;
	flex-direction:column; 
	padding-leftx:.5em;
	padding-right:2em;
	width:100%;
	margin-leftx:1em;
	margin-right:3em;

	}

	.detail-page-fields-group-column > * {
	    margin-bottom: .5em;
	}

	.detail-page-major-labels {
		font-size: 0.875rem;
		margin-left:7px;
	    /*min-height: 48px;*/
	    text-align: left;
	    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	    font-weight: 500;
	    line-height: 1.75;
	    white-space: normal;
	    letter-spacing: 0.02857em;
	    text-transform: uppercase;
	    color: #009688
	}

}




.detail-page-fields-group-left-column {
	width:100%;
}

.detail-page-fields-group-right-column {
	width:100%;
}

.detail-page-fields-group-action-button {
	display:flex;
    justify-content:flex-start;
    align-items:center;
    margin-top:3em;
    margin-bottom:3em;
}


.expansion-wrapper {
	margin-topx:1em;
	margin-leftx:2em;
	margin-rightx:2em;
}

.expansion-panel {
	borderx: solid 1px #d0d0d0;
	background-colorx: white;
								
}


.properties-subsection > * {
    margin-bottom: .5em;


}
.properties-subsection-field-wrapper {
	margin-left:8px;
}
.properties-subsection-image{
	padding-left: 8px;
}
.expansionPanelContent > * {
	margin-bottom: .5em;
}

.mainpage-left-column-tab-content {
	display:flex;
	position:relative;
	flex-direction:column;
	align-items:stretch;
	height:100%;
	width:100%;
}

.mainpage-left-column-tab-content-scrollbars {
	height:100%;
	position:relative;
}

.mainpage-left-column-tab-content-floating-action {
	display:flex;
	justifyContent:flex-end;
	margin:.2em .2em .2em .2em;
	position:absolute;
	bottom:5em;
	right:0em;
}
.format-center-toolbar-n-panels{
	display:flex;
	flex:1;
	flex-direction:column;
	justify-content:stretch;
}
.toolbar-wrapper {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items:center;
	flex-wrapx:wrap;
	alignItems:center;
	border-bottom:1px solid #707070;
}
.toolbar-content {
	max-width: 50%;
}

.toolbar-format-control-group {
	display:flex;
	flex-direction:row;
	justify-content:flex-start;
	padding:0em .2em 0em .2em;
	align-items:center;
}
.toolbar-format-control-group-element{
	display:flex;
	flex-direction:row;
	align-items:center;
}
.toolbar-control-group {
	display:flex;
	flex-direction:row;
	border-rightx:1px dashed #707070;
	padding:0em .2em 0em .2em;
	align-items:center;
}
.toolbar-menuitem-wrapper {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-start;
	margin-right: .5em;
	margin-left: .2em;
}
.toolbar-menuitem-title {

}
.toolbar-menuitem-section-title {
	text-align: center;
	font-weight: bold;
	color:#009688;

}
.toolbar-popup {
	  display:flex;
	  flex-direction:row;
	  justify-content:flex-start;
	  align-items:center;
	  borderx:1px dashed #cccccc;
}
.footer-part-title {
	color:white;
	margin:0em 1em 0em 1em;
	width:100%;
	display:flex;
	justify-content: center;
}
.real-editing-panels-wrapper{
	height:100%;
  	display:flex;
  	justify-content:flex-start;
  	flex-direction:column;
  	background-colorx:tan;
  	padding:.1em .1em .1em .1em;
  	width: 100%;
}

.editing-change-notes-wrapper{
	paddingx:1em 1em 1em 1em;

}

.editing-change-notes-line-item{
	borderx:1px solid green;
	margin-top:.2em;
	background-colorx: #e0ebeb;
	margin-bottom:1em;
	border-bottom: 3px solid black;
}

.editing-change-notes-line-item-details{
	padding: 0em .5em .5em .5em;

}

.editing-change-notes-line-item-controls {
	padding: .5em .5em .5em .5em;
}
.editing-toolbar-wrapper {
	display:flex;
	justify-items:space-between;
	align-items:center;
}

.editing-toolbar-item {
	width: 0.75em;
    height: 0.75em;
    margin: 5px;
}

#editingChangeNotesContainer{
	background-colorx: yellow;
	paddingx:0em .2em 0em .2em;
	height:100%;
  	display:flex;
  	justify-content:space-between;
  	flex-direction:column;
}

.split-screen-toolbar {
	width:.75em; 
	height:.75em;
}
.top-quill-editor {
	
}
.top-quill-editor-selected {
	background-colorx: #ffffff;
	borderx: 1px solid #009688;
}

.top-quill-editor-unselected {
	background-colorx: #E1E4EA;
	colorx:gray;
	opacityx:0.7;
}

.detail-page-fields-group-wrapper{
	height:100%;
	width:100%;
	display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.detail-page-primary-button {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	
}
.tree-node-edits-exist {
	color:red;
	font-weight:bold;
}

.scene-editor-scrolling-container {
	position:relative;
	borderx:1px dotted #707070;
	border-radiusx:.5em;
	margin-bottomx:.5em;
	background-colorx: #ffffff;
	/*009688*/

}
.scene-editor-disabled-overlay {
	position:absolute;
	width:100%;
	height:100%;
	opacity:0.5;
	z-index:9999;
	background-color:grey;
}


.scene-header-quill {
	clear:both;
	display:flex; 
	justify-content: center; 
	overflow:hidden; 
	margin-top:20px; 
	margin:0px .5em .5em .5em;
	borderx:1px solid red;
}
.scene-header-quill-title {
	color:lightgray;
	line-height: 1.5em;
	font-size: 1.5em;
}
.bookmerlin-paper {
	background-color: #ffffff;
}	
.part-wrapper {
	border-bottomx: dashed 1px rgba(0, 150, 136, .2);
	min-height:50em;
	margin-bottom: 1em;
}
.part-container-quill {
	background-color: #ffffff;
	padding-left:.4em;
	padding-right:.4em;
}
.part-container {
	background-colorx: #ffffff;
}						
.part-header-quill-title {
	
	color: rgba(0,0,0,0.6);
	line-heightx: 2.5em;
	font-size: 4em;
	padding-top: 2em;
    padding-bottom: 2em;

}					
.part-header-editing-title {
	
	color: rgba(0,0,0,0.6);
	font-size: 4em;
	width:100%;
	text-align: center;

}
.part-header-quill-title-scene {
	color:lightgray;
	font-size: 1.5em;

}
.part-header-quill-words-row {
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding-right:.5em;
	background-colorx: #ffffff;
	border-radiusx: .5em;
    margin-bottomx: .5em;
}
.part-header-quill-words{
	color:lightgray;
	line-heightx: 1.5em;
	font-size: 1.2em;
	display:flex;
	align-items:center;

}
.part-header-editing-transfer-button {
	border:1px solid #009688;
	color:#009688;
	border-radius: .5em;
	padding:0em .5em 0em .5em;
	

}

.editor-note-wrapper {
	display:flex;
	flex-direction:column;
	background-color:#feff9c;
	background-colorx:#F5D76E;
	background-colorx:#009688;
	opacityx:0.5;
	padding:1em 1em 1em 1em;
	margin:1em 15% 1em 15%;
	margin-top:1em;
	borderx:.2em dashed #707070;
	border-radius:1em;
}

.editor-note-controls {
	display:flex;
	flex-direction:row;
}
.ql-editor.ql-blank::before {
	font-size:20px;
}


.editing-wrapper {
		height:100%;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
	} 	

@media only screen and (max-width: 1024px) {
  .tab-content {
		
   }

  .writing-wrapper {
		height:100%;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
	}

	.editor .ql-editor {
	  font-size: 14px;
	  colorx: rgba(0,0,0,0.6);
	  overflow-y: visible; 
	  height: auto; 
	  padding: 0px .5em 0px .5em;
	  margin-left:.5em;
	  margin-right:1.5em;
	  border-topx: dashed 1px rgba(0, 150, 136, .2);
	}


}
@media only screen and (min-width: 1025px) {
  .tab-content {
		margin-left: 2em;
	    margin-right: 2em;
	    margin-topx:2em;
   }

  .writing-wrapper {
		height:100%;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		margin-left: 10%;
	    margin-right: 10%;
	}

	.editor .ql-editor {
	  font-size: 14px;
	  colorx: rgba(0,0,0,0.7);
	  overflow-y: visible; 
	  height: auto; 
	  padding: 0px .5em 0px .5em;
	  margin-leftx:5em;
	  margin-rightx:5em;
	  border-topx: dashed 1px rgba(0, 150, 136, .2);
	}

}


     <style type="text/css">
    html, body { 
      position:absolute;
      height: 100vh; 
      overflow:hidden;
      marginx:0px 0px 0px 0px;
      borderx: 3px solid blue;
      paddingx:10px 10px 10px 10px;
      background-colorx: #eeeeee;
    }

    #root {
      height:100vh;
      width:100vw;
      background-colorx: white;
      paddingx:10px 10px 10px 10px;
      overflow:hidden;
      
    }
    

          /* Tell Quill not to scroll */
          #editor {
            height: auto;
            min-height: 600px;
            padding: 50px;
          }
          #editor .ql-editor {
            font-sizexxxx: 18px;
            overflow-y: visible; 
          }

          /* Specify our own scrolling container */
          #scrollContainer {
            height: 100%;
            min-height: 100%;
            overflow-y: scroll;
          }

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}
.editorScroll {
  border: 0px solid #ccc;
}
.editor {
  heightx: 'none'; 

}

.editor .ql-container {
  height: auto;  
}

.ql-editor {
  heightX: auto; 
  padding: 0px 0px 0px 0px;
}

.editor li::before{
  display:'dummy';
}


.ql-container {
  height: auto; 
  text-indent: 1.25em;
}



.editor.ql-container p {
  height: auto; 
  text-indent: 1.25em;
  margin-bottom: 1.0em;
  line-height: 1.5em;
  font-size:1.5em;
}

.editor.ql-container p.floatLeft {
  text-indent:0;
  margin: 0em 0 0 0;
  padding: 0;
  text-align: center;
  float: left;
}

.editor.ql-container .floatLeft {
  text-indent:0;
  margin: 0em 0 0 0;
  padding: 0;
  text-align: center;
  float: left;
}


.editor.ql-container p.floatRight {
  text-indent:0;
  margin: 0em 0 0 0;
  padding: 0;
  text-align: center;
  float: right;
}

.editor.ql-container .floatRight {
  text-indent:0;
  margin: 0em 0 0 0;
  padding: 0;
  text-align: center;
  float: right;
}





.editor.ql-container.ql-snow {
    border: 0px dashed #ccc;
    padding: 0px 0px 0px 0px;
}

.editor.ql-toolbar.ql-snow {
   border: 0px solid #ccc;
   padding: 0px 0px 0px 0px;
}

.rst__virtualScrollOverride{
  overflow-x:none;
}

.editor.ql-container ol {
    text-alignx: center;
    list-style-position: inside;
    text-indent: 0em;
  
}
.editor.ql-container ul {
    text-alignx: center;
    list-style-position: inside;
    text-indent: 0em;
  
}

.subhead {
  font-weight:bold;
  margin-bottom: 0.8em;
  text-align: left !important;
}

decoratedBreak  {
  border: 1px dashed black;
}

.versecontainer {
  text-align: center;
}

.verseblock {
  display: inline-block; 
  text-align: left;

  clear:both;
  font-weight: normal;
  font-style: italic;
  text-align: left;
}
.verse {
  clear:both;
  text-indent:0em;
  font-weight: normal;
  font-style: italic;
  text-align: left;
  margin-bottom: 1em;
}
.attribution {
  clear:both;
  font-weight: normal;
  font-style: italic;
  text-align: right;
}




.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
 
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
 
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
 
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
 
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
 
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}













blockquote{
  font-size: 1.4em;
  widthx:60%;
  margin:50px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-leftx:8px solid #78C0A8 ;
  line-height:1.6;
  positionx: relative;
  backgroundx:#EDEDED;
}

blockquotex::before{
  font-family:Arial;
  content: "\201C";
  color:#707070;
  font-size:4em;
  position: absolute;
  left: -.2em;
  top:-.4em;
  margin-right:.3em;
  padding-left: .2em;
}

blockquotex::after{
  content: '';
}



.boundedblockquote {
  text-indent: 0em;
  border-topx: 1px solid #ccc;
  border-bottomx: 1px solid #ccc;
  margin-top: 1.5em;
  margin-right: 4.5em;
  margin-bottom: 1.5em; 
  margin-left: 4.5em;
  padding: 0;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 1.5em;

}

.ql-snow .ql-editor a {
    text-decoration: unset;
}

.authentication-button {
	background-color:rgb(243, 156, 18);
}

.auth-form-wrapper {

	display:flex;
	flex-direction:column;
	justify-content: center;
	borderx:1px solid red;
	height:100%;
	align-items:center;

}

.auth-form {
	display:flex;
	flex-direction:column;
	background-color: white;
	padding:2em 2em 2em 2em;
	border-radius:1.5em;
	position:relative;
}

.auth-form-header {

	position:absolute;
	top:-1.5em;
	left:10%;
	height:3em;
	width:80%;
	background-color: rgb(243, 156, 18);
	display:flex;
	justify-content:center;
	align-items:center;
	color:white;
	border-radius:.5em;
	margin-bottom: 2em;
	font-size: 1.2em;
}
.auth-form-button-row {
	display:flex;
	flex-direction:row;
	width:100%;
	borderx:1px solid red;
	justify-content:space-around;

}
.auth-form-button {

	border:#009688;
	backgroundColor:white;
    color:#009688;

}

.auth-form-error {
	color:red;
}

.auth-form-label {
	margin-top:2em;
}

.tabs-wrapper {
	height:100%;
	display: flex;
    flex-direction: column;
}
.empty-content-wrapper {

	background-colorx:#707070;
	flex:1;
	display:flex;
	flex-direction:column;
	justify-content:center;
	height:100%;
}

.empty-content-detail-wrapper {

	height:100%;
	display:flex;
	flex-direction:column;
	justify-content:center;
}
.mobile-drawer {
	widthx:30em;
    overflow:initial
}

.mobile-drawer-bottom {
	width:100%;
    overflow:initial
}

.mobile-drawer-view {
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.mobile-header-toolbar {
	display:flex;
	justify-content:space-between;
}

.mobile-footer-wrapper {
	background-color: #009688
}

.mobile-footer-button-wrapper {
	display:flex;
	align-items:center;
	color:white;
	margin:.5em .5em .5em .5em;
}

.mobile-footer-button-wrapper-unselected {
	display:flex;
	align-items:center;
	color:#707070;
}

.mobile-footer-button-wrapper-selected {
	display:flex;
	align-items:center;
	color:white;
}

/***** Blots *****/
.blot-wrapper-contributors {
	borderx:2px solid red;
}

.blot-wrapper-publishers {
	borderx:2px solid red;
}


.blot-wrapper {
	background-colorx:rgb(224, 224, 224);
	background-colorx:#009688;
	opacityx:0.5;
	padding:1em 1em 1em 1em;
	margin:1em 1em 1em 1em;
	margin-top:1em;
	borderx:.2em dashed #707070;
	border-radiusx:1em;
}
.blot-wrapperxxx {
	background-color:#f5faf9;
	padding:1em 1em 1em 1em;
	margin-top:1em;
	border:1px solid #707070;
	border-radius:1em;
}

.blot-lineitem {
	position:relative;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	margin:.5em .5em 2em .5em;
	heightx:2em;
	borderx:1px dotted blue;
	padding-right:2em;
	
}

.blot-lineitem-alsoby {
	justify-content:flex-start;
	borderx:1px dotted blue;

	
}

.blot-lineitem-fields {
	display:flex;
	flex:10;
	flex-direction:column;
	justify-content:space-between;
	
}

.blot-lineitem-controls {
	position:absolute;
	right:-100px;
	bottom:0px;
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items: center;
	margin-left:2em;
	
}

.blot-lineitem-textarea {
	background-color:transparent;
	border: 0px;
	width:100%;
	line-height: 1.0em;
    font-size: 1.5em;
    resize: none;
    height: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

	
}
.blot-lineitem-title-subtitle-div {
	display:flex;
	flex-direction:column;
}

.blot-lineitem-title-subtitle-textarea {
	font-style: italic;
}

.blot-lineitem-textarea::-webkit-scrollbar { 
	width: 0 !important 
}

.blot-lineitem-textarea:focus { 
    outline: none !important;
    border: 0px;
}
.blot-lineitem-button { 
    color: #707070;
    margin-top: 1em;
}
.blot-publisher-wrapper {
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}
.blot-blurb-content-italic {
	font-style: italic;
}

.blot-blurb-content-attribution {
	width:100%;
	borderx: 1px solid #eee;
	text-align: right;
}
.blot-contributor-name {
	font-style: italic;
}
.format-panel-subsection-title {
	display:flex;
	font-size: 1.0rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color:#707070;
    margin-left: 8px;
}

.format-panel-subsection-divider {
	border-bottomx:1px solid #707070;
	margin-bottom: 4em;
}



.editingBlot {


}

.editingBlotHidden {

displayx:none;
  

}

.editingDeleteBlot {
  

}

.editingDeleteBlotPending{
	border-top:2px solid red;
	border-bottom:2px solid red;
	colorx:red;
  borderx:1px solid red;
  text-decoration:line-through;
  text-decoration-color: red;
}

.editingDeleteBlotAccepted{
	
  border-top:2px solid red;
  border-bottom:2px solid red;
  background-color: #c5e8d4;
  colorx:red;
  borderx:1px solid red;
  text-decoration:line-through;
  text-decoration-color: red;
}

.editingDeleteBlotRejected{
	border-top:2px solid red;
	border-bottom:2px solid red;
	background-color: pink;
  colorx:red;
  borderx:1px solid red;
  text-decoration:line-through;
  text-decoration-color: red;
}


.editingDeleteBlotPreview{
  background-color:yellow;
  borderx:3px solid green;
}









.editingFormatBlot {
  
	text-decoration:overline;
	text-decoration-color: blue;
  
  }

.editingFormatBlotPending{
	border-top:2px solid red;
	border-bottom:2px solid red;
	text-decoration:wavy;
	text-decoration-color: red;
}

.editingFormatBlotAccepted{
	
  border-top:2px solid red;
  border-bottom:2px solid red;
  background-color: #c5e8d4;
  colorx:red;
  borderx:1px solid red;
  text-decoration:line-through;
  text-decoration-color: red;
}

.editingFormatBlotRejected{
	border-top:2px solid red;
	border-bottom:2px solid red;
	background-color: pink;
  colorx:red;
  borderx:1px solid red;
  text-decoration:line-through;
  text-decoration-color: red;
}


.editingFormatBlotPreview{
  background-color:yellow;
  borderx:3px solid green;
}
































.editingMovedTombstoneBlot {
  
	color:black;
	background-color: darkgrey;
  
  }

  .editingMovedDestinationBlot {
	colorx:yellow;
	background-colorx: tan;
	borderx: 1px dotted white;
	padding: .1em 0em .1em 0em;
  border-top:2px solid yellow;
	border-bottom:2px solid yellow;
  }

.editingInsertBlot {
  
  colorx:forestgreen;
  padding: .1em 0em .1em 0em;
  border-top:2px solid forestgreen;
  border-bottom:2px solid forestgreen;

}

.editingInsertBlotPending {
  
  borderx:1px solid red;

}

.editingInsertBlotAccepted{
  background-color: #c5e8d4;
  borderx:1px solid green;
}

.editingInsertBlotRejected{
  background-color: pink;
  borderx:1px solid green;
}

.editingInsertBlotPreview{
  background-color:yellow;
  borderx:3px solid green;
}

.editingDeleteFinalViewAccepted{
  display:none;
}

.editingInsertFinalViewAccepted{
  
}

.editingDeleteFinalViewRejected{
  
}

.editingInsertFinalViewRejected{
  display:none;
}

.editingNewLineBlotImg{
	background-color: #c5e8d4;
}

.row-container {display: flex; width: 100%; height: 100%; flex-direction: column; background-colorx: blue; overflow: hidden;}
.first-row {background-colorx: lime; }
.second-row { flex-grow: 1; border: none; margin: 0; padding: 0; }



.format-device-screen {
	position:absolute;
	top:6.5%;
	left:12%;
	height:83%;
	width:76%;
	background-color: tan;
}
.format-device-screen-epubjs {
	border-radius: 1em;
}
.format-device-button-export{
	position:absolute;
	zIndex:999;
	top:2.0%;
	left:5%;
	color:rgb(224, 224, 224);
}

.format-device-button-settings{
	position:absolute;
	zIndex:999;
	top:2.0%;
	right:-2%;
	color:rgb(224, 224, 224);
}

.format-device-button-prev{
	position:absolute;
	top:45%;
	left:3%;
	color:rgb(224, 224, 224);
	font-size:2.5em;
	opacity:.2;
	z-index:9999;
}

.format-device-button-next{
	position:absolute;
	top:45%;
	right:3%;
	color:rgb(224, 224, 224);
	font-size:2.5em;
	opacity:.2;
	z-index:9999;
}
.epub-container{
	border-radius: 1em;
}
.line-item-button{
	margin-left:.1em;
	margin-right:.1em;
}
.line-item-image{
	height:4em;
	width:4em;
	border:1px solid red;
}
.amplify-s3-image {
  height: 200px;
  width: 400px;
}
.newline {
	background-color:red;
}
.template-dynamic-params {
	display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
}
.template-dynamic-param-wrapper {
	margin-bottom: 3em;
	borderx:1px solid gray;
	display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-itemsx: center;
}
.template-dynamic-param-fieldset {
	align-items: center;
    width: 100%;
    margin-bottom: 2em;
}
.template-dynamic-param-label {
	color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.dev-data-verify{
	font-size:.5em;
}

.splitscreen-header-unselected{
	height:1em;
}

.splitscreen-header-selected{
	height:1em;
	background-color: gray;
	color:white;
}
.tag-selector-wrapper {
	margin: 1em 1em 1em 1em;
}
.pre-tree-title {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #009688;
    margin-left:.5em;
}

.gallery-button {
	color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.beta-text {

	color:white;
	font-weight: 450;
	margin-right:5px;
}



.account_subscription_container {
	display:flex;
	flex-direction:row;
	align-items:center;
}

.account_subscription_container_left {
	
}

.account_subscription_container_right {
	
}

.account_subscription_icon {
	height:4em;
	width:auto;
}

.account_subscription_details {
	
}
.build-properties-editor-container{
	heightx:100%;
	borderx:2px solid red;
	display:flex;
	justify-content: stretch;
	padding-bottom: 5em;
	
}
.build-properties-editor-details{
	display:flex;
	flex-direction:row;
	height:100%;
}
.build-properties-editor-details-left{
	height:100%;
}
.build-properties-editor-details-right{
	height:100%;
}



.popperRadioButton {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.popperRadioButton input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.popperRadioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.popperRadioButton:hover input ~ .popperRadioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.popperRadioButton input:checked ~ .popperRadioCheckmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.popperRadioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.popperRadioButton input:checked ~ .popperRadioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.popperRadioButton .popperRadioCheckmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

.property-element-subheading {
	display:flex;
	justify-content:flex-start;
	align-items:flex-end;
	color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.00938em;
	margin-right:0em;
	width:100%;
	border:0px solid green;
}

.property-element-item-image-selector-radio-wrapper{
	display:flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
}

.property-element-subheading-margin{
	margin-top:3em;
}
.property-element-item {
	display:flex;
	flex-direction: row;
	justify-content:flex-end;
	align-items:center;
	heightx:3em;
	margin-bottomx: 3em;
	margin-rightx: 2em;
	padding-rightx:2em;
	borderx:1px solid red;
	width:100%;
}
.property-element-item-selector {
	display:flex;
	flex-direction: column;
	justify-content:flex-end;
	align-items:center;
	margin-right: 0em;
	padding-right:2em;
	borderx:1px solid red;
	width:100%;
}
.property-element-item-label {
	color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.property-element-item-title {
	display:flex;
	height:100%;
	width:100%;
	justify-content:flex-start;
	align-items:flex-end;
	color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.00938em;
	margin-right:0em;
	borderx:1px solid green;
}
.property-element-item-radio-buttons {
	display:flex;
	flex-direction:row;
	margin-right:0em;
	padding-right:0em;
	justify-content: flex-start;
}
.property-element-item-image-selector {
	height:5em;
	background-colorx: tan;
	borderx:1px solid rgba(0, 0, 0, 0.54);
}
.property-element-item-image-selector-row {
	display:flex;
	width:100%;
	align-items:center;
	justify-content:space-around;
}
.property-element-item-template-selector {
	height:5em;
	background-color: tan;
	border:1px solid rgba(0, 0, 0, 0.54);
}
.property-element-item-template-selector-row {
	display:flex;
	width:100%;
	align-items:center;
	justify-content:space-around;
}
.property-element-item-template-selector-column {
	width:100%;
}
.property-element-item-radio-button-column {
	
}
.property-element-item-template-selector-selector {
	display:flex;
	width:100%;
	justify-content:flex-end;
	align-items:center;
}
.property-element-item-template-title {
	font-weight: 700;
}
.property-element-item-template-selector-more-properties {
	borderx:1px solid black;
	display:flex;
	justify-content:flex-end;
	align-items:center;
	width:100%;
}
.property-element-item-template-selector-more-properties-wrapper {
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
}
.property-element-item-more-properties-title {
	justify-content:flex-end;
	align-items:center;
	margin-right:0.5em;
	color:rgba(0, 0, 0, 0.54);
	font-weight: 500;
	
}
.carousel.carousel-slider .control-arrow:hover {
	background-color:green;
}
.property-element-item-checkbox {
	display:flex;
	flex-direction:row;
	justify-content:flex-end;
	align-items:center;
	margin-right:0em;
	padding-right:0em;
}
.property-element-item-image {
	position:relative;
	flex-basis:30%;
	borderx:1px solid blue;
}
.property-element-item-image-delete-icon {
	position:absolute;
    right:-1.5em;
    top:0em;
}
.number-type-cell {
	borderx: 1px solid red;
    width: 25%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top:.5em;
}
.flex-vertical-stretch {
	display:flex;
	flex-direction:column;
	justify-content:stretch;
}

.property-element-item-image-selector{
	
	text-align: center;
	display: flex;
    align-items: center;
    justify-content:center;

}

.property-element-item-centered-row{
	display:flex;
    flex-direction:column;
}

.property-element-item-centered-row-label{
	display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.00938em;
}
.property-element-info-text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}

.boxset-contributors-detail {
	display:flex;
	flex-direction:column;
	borderx:1px solid rgba(0, 0, 0, 0.54);
	margin-bottom:3em;
}

.boxset-contributors-detail-header {
	display:flex;
	flex-direction:row;
	margin-bottom: 3em;

}

.button-wrapper {
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-evenly;

}
.centered-paper {
	display:flex;
	align-items:center;
	justify-content:center;
	height:100%;
	width:100%;
	margin-left:1em;
	margin-right:1em;
}
.splitscreen-header-controls-wrapper {
	display:flex;
	borderx:1px solid grey;


}
.splitscreen-header-controls-panel{
	flex:6;
	borderx:1px solid grey;
}
.avatarx {
	vertical-align: middle;
	border-radius: 50%;
	border:1px solid black;
  }
.split-screen {
	background-colorx: red;
	margin-left:1em;
	margin-right:1em;
}
.single-screen {
	background-colorx: green;
	margin-left:5em;
	margin-right:5em;
}

.ant-tree-treenode-selected{
	flex-grow: unset;
} 

.ant-tree-node-content-wrapper-open{
	flex-grow: unset;
} 

.dark-mode-foreground-level-0 {
	color:rgba(0, 0, 0, 0.26);
}

.dark-mode-framing-level-1 {
	background-color:'#121212';
	color:'#009688';
}

.dark-mode-background-level-1 {
	background-color:#444444;
}


.dark-mode-foreground-level-1 {
	color:white;
	opacity:.5;
}

.dark-mode-framing-level-2 {
	background-color:'#121212';
	color:'#009688';
}

.dark-mode-background-level-2 {
	background-color:#181818;
}

.dark-mode-foreground-level-2 {
	color:white;
	background-color: #424242;
}

.dark-mode-side-foreground-level-0 {
	color:rgba(0, 0, 0, 0.26);
}

.dark-mode-side-foreground-selected-level-0 {
	color:#009688;
}

.dark-mode-side-foreground-level-2 {
	color:#grey;
}

.dark-mode-side-foreground-selected-level-2 {
	color:#009688;
}
.homePageLabel{
	color: white;
    padding: 6px 12px;
    overflow: hidden;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans- serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.show-dev-params {
	font-size:1.5em;
}
.change-note-icon {
	margin-left:.5em;
	min-width: 24px;
}

.format-full-height {
	height:100%;
}

.format-tab-panel {
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	border: 2px solid yellow;

}

